@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}

.main-artical {
    overflow-x: hidden;
}

// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     overflow-x: hidden;
// }

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background-color: var(--bgColor2);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    cursor: pointer;
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
}

p {
    font-size: 15px;
    font-family: var(--regular);
    // padding-bottom: 15px;
}

*::-moz-selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--themeColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

.themeColor {
    color: var(--themeColor);
}

.darkBlue {
    color: var(--darkBlue);
}

.textPalceholder {
    color: var(--textPalceholder);
}

.whiteColor {
    color: var(--whiteColor);
}

.darkTextGradient {
    background: var(--DarkText);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

.lightTextGradient {
    background: var(--lightText);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.ButtonColorGradient {
    background: var(--ButtonColor);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.bg-textGrey {
    background: var(--textGrey);
}

.bg-white {
    background: var(--whiteColor);
}

.bg-themeColor {
    background: var(--themeColor);
}

.bg-darkBlue {
    background: var(--darkBlue);
}

.bg-Color {
    background: var(--bgColor);
}

.bg-Color2 {
    background: var(--bgColor2);
}

/* header-btn */
.header-btn {
    overflow: hidden;
}

.header-btn::after {
    height: 100%;
    left: -35% !important;
    top: 0 !important;
    transform: skew(50deg);
    transition-duration: 0.6s !important;
    transform-origin: top left;
    width: 0;
}

.header-btn:hover:after {
    height: 100%;
    width: 135%;
}

// .common-space {
//     padding: 50px 0;
// }

// .common-space-over {
//     margin-top: 100px;
//     margin-bottom: 100px;
// }

// .d-Flex {
//     display: flex;
//     align-items: center;
// }

// .d-Flex-column {
//     padding: 120px 0;
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     justify-content: center;
// }

// .banner-bg {
//     position: relative;
//     background-image: url('../images/png/banner-bg.png');
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
// }

// .banner-bg::after {
//     content: '';
//     width: 100%;
//     // height: 100vh;
//     position: absolute;
//     bottom: 0;
//     top: 0;
//     margin: auto;
//     background-image: url('../images/png/parallex-bg.png');
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
//     z-index: 1;

// }

// .about-bg {
//     position: relative;
//     background-image: url('../images/png/about-bg.png');
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: bottom;
// }


// .about-content {
//     background-image: url('../images/png/about-bg-2.png');
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: bottom;
// }

// .service-bg {
//     background-image: url('../images/png/service-bg.png');
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     background-position: bottom;
// }

// .achieve-bg {
//     position: relative;
//     background-image: url('../images/png/achieve-bg.png');
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: top;

//     .lazy-load-image-background {
//         position: relative !important;
//         width: 100% !important;
//     }
// }

// .client-bg {
//     background-image: url('../images/png/client-bg.png');
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: top;
//     padding: 80px 0;
// }

// .client-bg-2 {
//     background-image: url('../images/png/client-bg-2.png');
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: top;
// }

// .contact-main-bg {
//     position: relative;
//     background-image: url('../images/png/contact-main-bg.png');
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: bottom;
// }

// .contact-wrap-bg {
//     position: relative;
//     background-image: url('../images/png/contact-bg.png');
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     background-position: top;
// }



// .border-top-line {
//     position: relative;
//     padding: 25px 0 0;
//     bottom: 25px;

//     &::before {
//         content: '';
//         position: absolute;
//         width: 90%;
//         height: 1%;
//         left: 0;
//         right: 0;
//         top: 0;
//         margin: auto;
//         background-color: #E3E3E3;
//     }
// }

// .social-link {
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     right: 30px;
//     margin: auto;
//     display: flex;
//     gap: 15px;
//     z-index: 1000;
//     height: 200px;
//     flex-wrap: wrap;
//     width: 60px;

//     & svg {
//         cursor: pointer;
//     }
// }

/* Scrollbar Styling */
// .sale-content-scroll::-webkit-scrollbar {
//     width: 6px;
// }

// .sale-content-scroll::-webkit-scrollbar-track {
//     position: relative;
//     background-color: #ffe6a864;
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
//     backdrop-filter: blur(5px);
// }

// .sale-content-scroll::-webkit-scrollbar-thumb {
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
//     background: var(--DarkText);

//     &::before {
//         content: '';
//         position: absolute;
//         width: 15px;
//         height: 10px;
//         background-color: var(--DarkText);
//     }
// }

iframe {
    width: 490px;
    height: 310px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

/***************** Parallex effectg css*************************/
:root {
    --index: calc(1vw + 1vh);
    --transition: transform 0.75s cub-bezier(0.075, 0.5, 0, 1);
}

.content {
    will-change: transform;
}

.header-main {
    position: relative;
}

.header-main::after {
    content: '';
    position: absolute;
    z-index: 10;
    width: 100%;
    height: calc(var(--index) * 10);
    background: url('../images/png/ground.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    bottom: calc(var(--index) * -4.5);
}

.layers {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    height: 100vh;
}

.layer-head {
    z-index: 1;
    transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
    will-change: transform;
}

.caption {
    transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
}

.img-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover !important;
    background-position: center !important;
    will-change: transform;
    z-index: 2;
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}

.layer-base {
    transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    z-index: 0;
}

.layer-mid {
    transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
}

.layer-mid-up {
    transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
}

.layer-front {
    transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
}

.artical-main {
    --mainarticle-transform: translate3d(0, calc(var(--scrollTop)/ -7.6));
    position: relative;
    top: -2px;
    z-index: 10;
}

.layer-mid-2 {
    transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -webkit-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -moz-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -ms-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -o-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
}

// .layer-mid-X {
//     transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -webkit-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -moz-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -ms-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -o-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
// }

// .layer-mid-x-2 {
//     position: relative;
//     left: -370px;
//     transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -webkit-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -moz-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -ms-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -o-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
// }

.layer-mid-3 {
    transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -webkit-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -moz-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -ms-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -o-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
}

.arrows:hover {
    fill: var(--darkBlue);
    stroke: var(--whiteColor);
}

.arrows:hover>circle {
    fill: var(--darkBlue);
}

.message {
    position: fixed !important;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto !important;
}












/***************** Form *************************/
.border-bottom-line {
    position: relative;
    border-bottom: 1px solid #e1e4e8;
    // padding-bottom: 25px;
}

.label-heading {
    font-size: 16px;
    text-transform: math-auto;
}

.property-type-container,
.Commercial-container {
    display: flex;
    flex-direction: column;
}

.active-radio-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;


    input[type="radio"] {
        display: none;
    }

    &>.input-field {
        padding: 10px 50px;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            height: 18px;
            width: 18px;
            border: 2px solid #e1e4e8;
            border-radius: 50%;
            margin-right: 15px;
        }
    }

    & input[type="radio"]:checked+.input-field {
        background: var(--darkBlue);
        color: var(--whiteColor);
    }

    & input[type="radio"]:checked+.input-field::before {
        height: 18px;
        width: 18px;
        border: 5px solid #e1e4e8;
        background: var(--darkBlue);
    }
}

.active-check-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;

    input[type="checkbox"] {
        display: none;
    }

    &>.input-field {
        padding: 10px 50px;
        display: flex;
        align-items: center;
    }

    & input[type="checkbox"]:checked+.input-field {
        background: var(--darkBlue);
        color: var(--whiteColor);
    }
}

.check-box {
    $black: #363839;
    $gray: #bdc1c6;
    $white: #fff;

    label {
        position: relative;
        cursor: pointer;
        user-select: none;
    }

    input[type="checkbox"] {
        position: relative;
        width: 1.3em;
        height: 1.3em;
        color: $black;
        border: 1px solid $gray;
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

        &::before {
            position: absolute;
            content: '';
            display: block;
            top: 1px;
            left: 6px;
            width: 5px;
            height: 13px;
            border-style: solid;
            border-color: var(--whiteColor);
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
        }

        &:checked {
            color: $white;
            border-color: var(--darkBlue);
            background: var(--darkBlue);

            &::before {
                opacity: 1;
            }

            ~label::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }
    }


}

.select-district {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &>div {
        width: 33%;
    }
}

.modal-footer {
    justify-content: space-between !important;
}

.selected-data-show {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;

    &>span {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--darkBlue);
        border: 1px solid var(--darkBlue);
        padding: 4px 8px;
        border-radius: 6px;
        height: 43px;
        text-align: center;
        color: var(--whiteColor);
        font-size: 13px;
    }
}

.modal-content {
    max-height: 90vh;
    min-height: 100%;
    // overflow-y: scroll;
}

.modal-body {
    overflow-y: scroll;
}

.loading-btn {
    font-size: 18px;
    font-family: var(--semiBold);
    color: var(--darkBlue) !important;
}


.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--whiteColor) transparent;
    animation: spin 1s infinite ease-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.district-btn {
    display: flex !important;
    width: 100% !important;
    align-items: flex-end !important;
    justify-content: end !important;
}

/***************** RTL DIrection*************************/

html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body .rtl {
    position: relative !important;
    font-family: var(--arRegular);
    font-style: normal;
    font-size: 15px;
}

.rtl .contact-text,
.rtl input::placeholder,
.rtl select>option,
.rtl .selected-data-show>span,
.rtl select {
    font-family: var(--arRegular) !important;
}

.rtl .heading-connect {
    font-size: 26px !important;
}


.rtl .contact-svg,
.rtl .click-btn svg {
    transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -ms-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
}

.rtl .nav-bar-list a,
.rtl .footer-list a,
.rtl .modal-title {
    font-family: var(--arSemiBold) !important;
}

.rtl .nav-bar-list a.active {
    font-family: var(--arExtraBold) !important;
}

.rtl .active-radio-container>.input-field::before {
    margin-right: 0 !important;
    margin-left: 15px !important;
}

.rtl .modal-header .btn-close {
    margin: calc(-.5* var(--bs-modal-header-padding-y)) calc(47* var(--bs-modal-header-padding-x)) calc(-.5* var(--bs-modal-header-padding-y)) auto !important;
}