@media (max-width: 1399px) {}

@media (max-width: 1199px) {
  .logo svg {
    width: 140px !important;
  }
}

@media (max-width: 991px) {
  .lead-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }

}

@media (max-width: 768px) {
  .select-district>div {
    width: 50% !important;
  }
}

@media (max-width: 576px) {
  .rtl .main-heading {
    font-size: 24px !important;
  }

  .rtl .heading-connect {
    font-size: 22px !important;
  }

  .select-district>div {
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .heading-connect {
    font-size: 22px !important;
  }

  .rtl .main-heading {
    font-size: 20px !important;
  }

  .rtl .heading-connect {
    font-size: 20px !important;
  }

  .rtl .modal-header .btn-close {
    margin: calc(-0.5* var(--bs-modal-header-padding-y)) calc(6* var(--bs-modal-header-padding-x)) calc(-0.5* var(--bs-modal-header-padding-y)) auto !important;
  }
}

@media (max-width: 420px) {}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {}

@media (max-width: 1199px) {

  .rtl .footer-logo svg,
  .rtl .footer-content svg {
    width: 150px !important;
  }
}

@media (max-width: 991px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {}

@media (max-width: 480px) {}